export default {
    "{activeSlide} of {totalItems}": "{activeSlide} van {totalItems}",
    "{age} years": "{age} jaar",
    "{bmi_min} and {bmi_max}.": "{bmi_min} en de {bmi_max}.",
    "{from} and {to}": "{from} en {to}",
    "{height} cm": "{height} cm",
    "{kg} kg": "{kg} kg",
    "{recipeTitle} was added as favorite recipe": "Dit recept is toegevoegd als favoriet recept",
    "{recipeTitle} was removed as favorite recipe": "Dit recept is verwijderd als favoriet recept",
    "{weight} kilo": "{weight} kilo",
    "1 to 3 hours a week": "1 tot 3 uur per week",
    "100% free and after 14 days we will leave you alone, unless of course you are convinced of Project Gezond!": "100% gratis en na 14 dagen laten we je direct met rust, tenzij je natuurlijk overtuigd bent van Project Gezond!",
    "3 to 5 hours a week": "3 tot 5 uur per week",
    "6 to 7 hours a week": "6 tot 7 uur per week",
    "a day. To lose weight we recommend subtracting (maximum) 500 Kcal from this. You then arrive at": "per dag. Om af te vallen adviseren wij om hier (maximaal) 500 Kcal vanaf te trekken. Je komt dan uit op",
    "Add to home screen": "Zet op beginscherm",
    "Add weightlog": "Weegmoment toevoegen",
    "Add": "Voeg toe",
    "Age in years": "Leeftijd in jaren",
    "Age must be between 1 and 150": "Leeftijd moet tussen 1 en 150 zijn",
    "All the recipes in a list": "Al het lekkers op een rij",
    "and a weight of": "en een gewicht van",
    "and choose": "en kies",
    "and try it free for 14 days": "en probeer het 14 dagen gratis",
    "Apr": "Apr", // @required
    "April": "april", // @required
    "Are you sure you want to remove all of your weight logs?": "Weet je zeker dat je alle weegmomenten wilt verwijderen?",
    "Are you sure you want to schedule this daymenu?": "Weet je zeker dat je dit dagmenu wilt inplannen? ",
    "At least 8 characters": "Minstens 8 tekens bevatten",
    "At least contain 1 special character": "Tenminste één speciaal teken bevatten",
    "At this moment you have a": "Op dit moment heb je een",
    "Aug": "Aug", // @required
    "August": "augustus", // @required
    "Badly rated": "Minst beoordeeld", // @required
    "Based on your data your kcal requirement is": "Op basis van jouw gegevens is je caloriebehoefte",
    "Birthdate": "Geboortedatum",
    "BMI (Body mass index)": "BMI (Body mass index)",
    "BMI {eq}": "BMI {eq}",
    "BMI of {bmi}.": "BMI van {bmi}.",
    "BMI of {from} to {to}": "BMI van {from} tot {to}",
    "BMI": "BMI",
    "Breakfast": "Ontbijt", // @required
    "Calculate BMI": "BMI berekenen",
    "Calculate your calorieneed": "Caloriebehoefte berekenen",
    "Calculate": "Berekenen",
    "Calorie need": "Caloriebehoefte",
    "Calorieneed": "Caloriebehoefte",
    "Cancel": "Annuleren",
    "Change Password": "Wachtwoord wijzigen",
    "Choose a week": "Kies een week",
    "Click here": "Klik hier",
    "Clear, close this message": "Duidelijk, sluit deze melding",
    "Confirm registration": "Aanmelding bevestigen",
    "Continue": "Doorgaan",
    "Current Password": "Huidig wachtwoord",
    "Date": "Datum",
    "Day after tomorrow": "Overmorgen",
    "Day menu was added as favorite": "Dagmenu is toegevoegd aan je favorieten",
    "Day menu was removed as favorite": "Dagmenu is verwijderd uit je favorieten",
    "Day": "Dag",
    "Daymenu": "Dagmenu",
    "Daymenus with this recept": "Dagmenu's bij dit recept",
    "Dec": "Dec", // @required
    "December": "december", // @required
    "Dinner": "Diner", // @required
    "E.g. 170": "Bijv. 170",
    "E.g. 30": "Bijv. 30",
    "E.g. 70": "Bijv. 70",
    "Eating delicious food every day and losing weight in the meantime?": "Elke dag lekker eten en ondertussen afvallen?",
    "Email" : "E-mailadres",
    "Enter your current password": "Vul je huidige wachtwoord in",
    "Enter your new password again": "Vul je nieuwe wachtwoord nogmaals in",
    "Enter your new password": "Vul je nieuwe wachtwoord in",
    "extremely overweight": "extreem overgewicht",
    "Extremely overweight": "Extreem overgewicht",
    "fairly overweight": "ernstig overgewicht",
    "Fairly overweight": "Ernstig overgewicht",
    "Feb": "Feb", // @required
    "February": "februari", // @required
    "Female": "Vrouw",
    "Files": "Bestanden",
    "Fill in details": "Vul je details in",
    "Fill in your length": "Vul je lengte in",
    "Fill in your weight": "Vul je gewicht in",
    "Filter": "Filteren",
    "Find a recipe": "Zoek een recept",
    "First Name": "Voornaam",
    "Firstname": "Voornaam",
    "For a": "Voor een",
    "Forgot password?": "Wachtwoord vergeten?",
    "Fri": "Vr", // @required
    "Friday": "Vrijdag", // @required
    "from": "van",
    "Gender": "Geslacht",
    "Good afternoon": "Goedemiddag",
    "Good evening": "Goedenavond",
    "Good morning": "Goedemorgen",
    "Good night": "Goedenacht",
    "Grocery list": "Boodschappenlijst",
    "healthy weight": "gezond gewicht",
    "Healthy weight": "Gezond gewicht",
    "Height in cm": "Lengte in cm",
    "Height must be between 100 and 250": "Lengte moet tussen de 100 en 250 zijn",
    "Help with diet": "Hulp bij afvallen",
    "Hi": "Hoi!",
    "Hide password": "Verberg wachtwoord",
    "Highly rated": "Best beoordeeld", // @required
    "hour": "uur", // @required
    "How often do you exercise?": "Hoeveel sport je?",
    "I don't exercise": "Ik sport niet",
    "If you want to lose weight, you should eat {result} kcal less": "Wil je een <strong>pond per week afvallen</strong> dan moet je ongeveer <strong>500 kcal</strong> minder eten dan jouw dagelijkse caloriebehoefte. In jouw geval betekent dit dat je <strong>{result} kcal</strong> per dag mag eten.",
    "Ingredients": "Ingrediënten",
    "Install Project Gezond as app": "Installeer Project Gezond als app",
    "Jan": "Jan", // @required
    "January": "januari", // @required
    "Jul": "Jul", // @required
    "July": "juli", // @required
    "Jun": "Jun", // @required
    "June": "juni", // @required
    "Kcal from {min} to {max}": "Kcal van {min} tot {max}",
    "Kcal to lose weight": "Kcal om af te vallen",
    "Kcal: high to low": "Kcal: hoog-laag", // @required
    "Kcal: low to high": "Kcal: laag-hoog", // @required
    "kcal": "kcal",
    "Kcal": "Kcal",
    "kilos to have a healthy weight.": "mag wegen om een gezond gewicht te hebben.",
    "Last Name": "Achternaam",
    "Length (in cm)": "Lengte (in cm)",
    "length of": "lengte van",
    "Let's get started with your personal information. With this we can calculate your calorie needs, and you can easily track your progress. Filling this is only takes one minute.": "Laten we direct enkele gegevens invullen. Dan kunnen wij je caloriebehoefte berekenen en kun jij heel simpel je voortgang bijhouden. Invullen duurt slechts één minuut.",
    "Load more recipes": "Laad meer recepten",
    "Load more weight logs": "Laad eerdere weegmomenten",
    "Log out": "Uitloggen",
    "Login": "Inloggen",
    "Lose 0.5 to 1 kilo per week without feeling hungry": "Val 0,5 tot 1 kilo per week af zonder een hongergevoel te hebben",
    "Lunch": "Lunch", // @required
    "Male": "Man", // @required
    "Mar": "Mrt", // @required
    "March": "maart", // @required
    "May": "mei", // @required
    "minutes": "minuten", // @required
    "Mon": "Ma", // @required
    "Monday": "Maandag", // @required
    "Month": "Maand",
    "More info": "Meer info",
    "More than 250 easy and especially tasty recipes": "Meer dan 250 makkelijke en vooral lekkere recepten",
    "More": "Meer",
    "Must be at least 8 characters": "Wachtwoord moet minstens 8 tekens bevatten",
    "Must contain at least one lowercase letter, one uppercase letter, one digit and one special character": "Wachtwoord moet minstens één kleine letter, één hoofdletter, één cijfer en één speciaal teken bevatten",
    "My details": "Mijn gegevens",
    "My favorite daymenus": "Mijn favoriete dagmenu's",
    "My favorite recipes": "Mijn favoriete recepten",
    "My PG": "Mijn PG",
    "My planned daymenus": "Mijn geplande dagmenu's",
    "My Project Gezond": "Mijn Project Gezond",
    "My weight log": "Mijn weegmomenten",
    "My Weight Logs": "Mijn weegmomenten",
    "Never difficult calculations, but always enjoy": "Nooit lastig rekenwerk, wel altijd genieten",
    "New Password": "Nieuw wachtwoord",
    "New update available": "Nieuwe update beschikbaar",
    "New version is: <strong>{version}</strong>": "Nieuwe versie is: <strong>{version}</strong>",
    "New-old": "Nieuw-oud", // @required
    "Nibbles": "Tussendoortje", // @required
    "No recipes found": "Geen recepten gevonden",
    "No thanks": "Nee bedankt",
    "No": "Nee",
    "Not a valid e-mail address": "Dit is niet een geldig emailadres",
    "Not yet a member of Project Healthy?": "Nog geen lid van Project Gezond?",
    "Nov": "Nov", // @required
    "November": "november", // @required
    "Oct": "Okt", // @required
    "October": "oktober", // @required
    "Ok": "Ok",
    "Old-new": "Oud-nieuw", // @required
    "Open daily menus standard in the following kcal variant:": "Open dagmenu's standaard in de volgende calorievariant:",
    "Other questions": "Overige vragen",
    "overweight": "overgewicht",
    "Overweight": "Overgewicht",
    "Password": "Wachtwoord",
    "Passwords do not match": "Wachtwoorden komen niet overeen",
    "Personal Info": "Persoonlijke gegevens",
    "Plan in": "Plan in",
    "Planned daymenu by you": "Door jou gepland dagmenu",
    "Please enter a valid value": "Voer alle velden in",
    "Please fill in a reply": "Je hebt geen reactie ingevuld",
    "Please fill in all fields": "Vul alle velden in",
    "Please first fill in all fields": "Vul eerst alle velden in",
    "Press": "Druk op",
    "Progress": "Voortgang",
    "Question": "Vraag",
    "Questions? info@projectgezond.nl": "Vragen? info@projectgezond.nl",
    "Rate this recipe": "Beoordeel dit recept",
    "rating": "beoordeling",
    "ratings": "beoordelingen",
    "Recipe rated succesfully": "Recept succesvol beoordeeld",
    "Recipes in this menu": "Recepten in dit menu",
    "Recipes in this weekmenu": "Komende dagen op het menu",
    "Recipes": "Recepten",
    "Remove all weightlog": "Verwijder alle weegmomenten",
    "Reply on this recipe": "Reageer op dit recept",
    "Reply posted": "Reactie achtergelaten",
    "Reply to the above comment": "Reageer op bovenstaande reactie",
    "Reply to this comment": "Reageer op deze reactie",
    "Reply": "Reageer",
    "Reset": "Reset",
    "Sat": "Za", // @required
    "Saturday": "Zaterdag", // @required
    "Save as favorite": "Opslaan als favoriet",
    "Save information": "Gegevens updaten",
    "Schedule daymenu": "Dagmenu inplannen",
    "Search for a specific recipe": "Vind een specifiek recept",
    "Search": "Zoeken",
    "seconds": "seconden", // @required
    "Select an option": "Selecteer een optie",
    "Send": "Versturen",
    "Sep": "Sep", // @required
    "September": "september", // @required
    "Show own daymenu": "Toon eigen dagmenu",
    "Show password": "Toon wachtwoord",
    "Show Recipe": "Toon recept",
    "Show Recipes": "Toon recepten",
    "Since {date} ({weight} kg)": "Sinds {date} ({weight} kg)",
    "Skip": "Sla over",
    "Snack": "Snack", // @required
    "Start date": "Startdatum",
    "Start Weight": "Startgewicht",
    "Start": "Start",
    "Su": "Zo", // @required
    "Subject": "Onderwerp",
    "Submit reply": "Reactie plaatsen",
    "Succesfully added weight log": "Je weegmoment is toegevoegd",
    "Sun": "Zo", // @required
    "Sunday": "Zondag", // @required
    "Target Weight": "Streefgewicht",
    "Th": "Do", // @required
    "Thank you!": "Bedankt!",
    "Thanks to the six eating moments a day, you will never suffer from an enormous appetite.": "Dankzij de zes eetmomenten per dag heb je nooit last van enorme trek.",
    "Thanks!": "Bedankt!",
    "Thanks": "Bedankt",
    "That puts you in the category": "Daarmee val je in de categorie",
    "That's how you make it": "Zo maak je het",
    "the following values apply.": "gelden de volgende waarden.",
    "The variant of the weekly menu that is closest to this is that of": "De variant van het weekmenu die hier het dichtst bij in de buurt ligt is die van",
    "There is already a daymenu on this date. Do you want to overwrite this?": "Er staat al een dagmenu op deze datum. Wil je deze overschrijven?",
    "There was an error posting your reply.": "Er is een probleem bij het achterlaten van je reactie.",
    "There's a new update available, would you like to update?": "Er is een nieuwe update beschikbaar, wil je updaten?",
    "This App functions even better when you install it. Install the Project Gezond App on the home screen.": "Deze App functioneert nog beter als je hem installeert. Installeer de Project Gezond App op de startscherm.",
    "This App functions even better when you install it": "Deze App functioneert nog beter als je hem installeert",
    "This means that with a": "Dit betekent dat jij met een",
    "Thu": "Do", // @required
    "Thursday": "Donderdag", // @required
    "Tip {tipIndex}": "Tip {tipIndex}",
    "Today": "Vandaag",
    "Tomorrow": "Morgen",
    "Total {totalItems} recipes": "Totaal {totalItems} recepten",
    "Total 1 recipe": "Totaal 1 recept",
    "Total lost weight": "In totaal afgevallen",
    "Try Project Gezond for 14 days for free!": "Probeer Project Gezond nu 14 dagen gratis!",
    "Tue": "Di", // @required
    "Tuesday": "Dinsdag", // @required
    "Unauthenticated.": "Niet ingelogd.", // @required
    "underweight": "ondergewicht",
    "Underweight": "Ondergewicht",
    "Unfortunately...": "Helaas...",
    "Unlimited personal support, a friendly Facebook group, handy variation lists, a dashboard for your progress and many more extras.": "Onbeperkt persoonlijke ondersteuning, gezellige Facebookgroep, handige variatielijsten, een dashboard voor je voortgang en nog heel veel extra’s.",
    "Update now": "Update nu",
    "Uppercase and lowercase characters": "Hoofdletters en kleine letters bevatten",
    "Use the calculator below to calculate your daily calorie requirement. In the table with results you can see how many calories you can eat per day to <strong>maintain your weight</strong> and how many calories you can eat per day to lose <strong>half a kilo per week</strong >.": "Gebruik de onderstaande calculator om te berekenen wat jouw dagelijkse caloriebehoefte is. In de tabel met resultaten kun je zien hoeveel calorieën je per dag mag eten om <strong>op gewicht te blijven</strong> en hoeveel calorieën je per dag mag eten om een <strong>halve kilo per week af te vallen</strong>.",
    "Use the form below to calculate your BMI.": "Gebruik het onderstaande formulier om je BMI te berekenen.",
    "Useful links": "Handige links",
    "Version: {version}": "Versie: {version}",
    "View and manage all weightlogs": "Bekijk en beheer alle weegmomenten",
    "View planned day menus": "Toon geplande dagmenu's",
    "Watch out!": "Let op!",
    "We have sent you an email with instructions on how to reset your password.": "We hebben je een e-mail gestuurd met instructies om je wachtwoord te resetten",
    "We have worked out every daily menu completely for you. So you don't have to count or keep track of anything yourself.": "Wij hebben elk dagmenu compleet voor je uitgewerkt. Je hoeft dus niets te tellen of zelf bij te houden.",
    "We will send you a link by email to set up your personal passwords. Then you can get started right away.": "We sturen je per e-mail een link om je persoonlijke wachtwoord in te stellen. Daarna kun je direct aan de slag.",
    "Wed": "Wo", // @required
    "Wednesday": "Woensdag", // @required
    "Week {num}": "Week {num}",
    "Week menu": "Weekmenu",
    "Weekmenus": "Weekmenu's",
    "Weight (in kg)": "Gewicht (in kg)",
    "Weight in kg": "Gewicht in kg",
    "Weight logs": "Weegmomenten",
    "Weight must be between 30 and 300": "Gewicht moet tussen 30 en 300 zijn",
    "Weight": "Gewicht",
    "Weightlog deleted": "Weegmoment verwijderd",
    "Welcome {user}!": "Welkom {user}!",
    "Welcome back": "Welkom terug",
    "Welcome!": "Welkom!",
    "When you don't sport": "Wanneer je niet sport:",
    "With {min} to {max} hours of sport per week:": "Bij {min} tot {max} uur sport per week:",
    "with a length of": "met een lengte van",
    "With the weekly menus and recipes of Project Gezond you will reach your ideal weight with a smile!": "Met de weekmenu’s en recepten van Project Gezond bereik je lachend je ideale gewicht!",
    "Write your reply on this recipe": "Schrijf hier je reactie op dit recept",
    "Year": "Jaar",
    "Yes": "Ja",
    "you can weigh between": "tussen de",
    "You get access to over 250 delicious recipes with step-by-step instructions": "Je krijgt toegang tot ruim 250 heerlijke recepten met stap-voor-stap instructies",
    "You get everything you need to lose weight successfully": "Je krijgt alles wat je nodig hebt om succesvol af te slanken",
    "You have a healthy weight if your BMI is between": "Je hebt een gezond gewicht als jouw BMI tussen de",
    "You have planned a daymenu here": "Je hebt hier al een dagmenu gepland",
    "You haven't planned a daymenu yet": "Je hebt nog geen dagmenu gepland",
    "You'll receive a response within 2 days for your comment or question.": "Je ontvangt binnen twee werkdagen een reactie op je vraag of bericht.",
    "Your information has been updated.": "Je gegevens zijn geüpdatet.",
    "Your new password requires:": "Je nieuwe wachtwoord moet:",
    "Your password is set, you are ready to login!": "Je wachtwoord is ingesteld en je bent klaar om te beginnen!",
    "Your prefered calorievariant has been set to {calories}": "Je voorkeurs calorievariant is ingesteld op {calories}",
    "Your question": "Je vraag",
    "Load more comments": "Meer reacties laden",
    "Comments ({num})": "Reacties ({num})",
    "Tips & Tricks": "Tips & Tricks",
    "You searched for ''{query}''": "Je zocht op ''{query}''", // @required
    "Your device runs on the iOS {version} operating system. Our app does not work optimally because of this. Update to a newer one if possible iOS version to better use the app.": "Jouw toestel draait op het besturingssysteem iOS {version}. Onze app werkt hierdoor niet optimaal. Update indien mogelijk naar een nieuwere iOS versie om de app beter te kunnen gebruiken.",
    "Day menu is now scheduled in": "Dagmenu is nu ingepland",
    "Page not found": "Pagina niet gevonden",
    "Can't reach server, please check your internet connection and try again.": "We kunnen niet met de server verbinden, check je internetverbinding en probeer het opnieuw",
    "Your device has cookies disabled, this is required for the app to work.": "Je apparaat heeft cookies uit staan. Om onze app goed te kunnen gebruiken is het belangrijk dat jouw apparaat cookies toestaat. Volg de stappen hieronder:",
    "On Chrome (Android)": "Op Chrome (Android)",
    "On your Android phone or tablet, open the Chrome app Chrome.": "Open je Chrome app op je Android telefoon of tablet.",
    "At the top right, tap More More and then Settings.": "Druk op het meer-icoontje rechtsbovenin, en dan 'Instellingen'.",
    "Tap Site settings and then Cookies.": "Druk op 'Site instellingen' en dan 'Cookies'",
    "Turn Cookies on.": "Zet het vinkje aan.",
    "On Safari (iOs)": "Op Safari (iOs)",
    "Go to Settings, then scroll down and select “Safari”.": "Ga naar je instellingen, scroll omlaag en druk op 'Safari'.",
    "Scroll down to “Privacy & Security”.": "Scroll omlaag en druk op 'Privacy & Security'.",
    "Verify “Block All Cookies” is unticked, click to allow cookies.": "Zorg ervoor dat 'Blokeer alle cookies' uit staat.",
    "Clear the browser cache and reopen the browser.": "Leeg je browsercache en open Safari opnieuw.",
    "Cooking mode": "Kookstand",
    "Activate the cooking mode to prevent your screen from turning off while cooking.": "Activeer de kookstand om te voorkomen dat je scherm uit gaat tijdens het koken.",
};
